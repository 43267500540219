<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>

        <div class="list v2-g-w1200">
            <!-- 系统提示 -->
            <div class="tips-box" v-if="isShowTips">
                <p>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    风险客户无法下单！您的资信超期，还款后第二天自动解除限制。
                </p>
                <p>
                    <u @click="nowArrears">去还款</u>
                    <img src="@/assets-v2/images/close_icon.svg" alt @click="isShowTips = false" />
                </p>
            </div>
            <div class="bar v2-g-flex-row">
                <div class="v2-g-flex-row">
                    <div class="btn link-color" :class="!isSelectPromotionTab ? 'sel' : ''"
                        @click="isSelectPromotionTab = false;promiton_tb=false;">
                      全部商品 <span v-if="totalCount > 0">{{ totalCount }}</span>
                    </div>
                    <div class="btn link-color" :class="isSelectPromotionTab ? 'sel' : ''"
                        @click="isSelectPromotionTab = true;promiton_tb=true">
                      优惠明细 <span v-if="promotionLst.length > 0">{{ promotionLst.length }}</span>
                    </div>
                    <div class="pro">
                        <span>特价立减:<i>￥{{ cardInfo.reducedPriceTotal }}</i>元</span>
                        <span v-if="!cardInfo.piaoZhe3TrueOrFalse">现金返点:<i>￥{{ cardInfo.cashAmount }}</i>元</span>
                        <span>红包返：<i>￥{{ cardInfo.redPacketMoneyTotal }}</i>元</span>
                        <span v-if="cardInfo.cartOrderReturnProportionVO && cardInfo.cartOrderReturnProportionVO.isShow === 1">
                          299凑单返利：<i>￥{{ cardInfo.isMax > -1 ? Number(cardInfo.giftAmount) : 0 }}</i>元
                        </span>
                        <span v-if="cardInfo.activityNextInfoVO">
                          整单立减：<i>￥{{ cardInfo.activityNextInfoVO.type == 0 ? 0 : cardInfo.activityNextInfoVO.reductionMoney }}</i>元
                        </span>
                    </div>
                </div>
                <div class="search">
                  <input type="text" placeholder="购物车内搜索" v-model="q" @input="search" />
                  <span class="btn-search link-color" @click="search"><i class="iconfont icon-search"></i>搜索</span>
                </div>
            </div>
            <div class=" t v2-g-flex-row v2-g-flex-space-between">
                <div class="sel" @click="selectAll" v-if="!promiton_tb">
                  <input type="checkbox" v-model="isAll" />全选
                </div>
                <div class="product">商品</div>
                <div class="price">单价</div>
                <div class="num">数量</div>
                <div class="total">小计</div>
                <div class="op" :class="{'yh':promiton_tb}">操作</div>
            </div>
            <template v-if="list.length > 0">
                <!-- 所有商品 -->
                <template v-if="!isSelectPromotionTab">
                    <div class="product-wrapper-col" v-for="item of list" :key="item.productId">
                        <ShoppingCartWaresItem :product="item" :isShopCard="true" :isShowSimilar="true" v-on:fresh="copyCard" />
                    </div>
                </template>
                <!-- 优惠商品 -->
                <template v-if="isSelectPromotionTab">
                    <template v-if="promotionLst.length > 0">
                        <div class="product-wrapper-col" v-for="item of promotionLst" :key="item.productId">
                            <ShoppingCartWaresItem :product="item" :isShopCard="true" :isShopCard_Promotion="true" :isShowSimilar="true" v-on:fresh="copyCard" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="nodata">
                            <img src="@/assets-v2/images/nodata.png" />
                            <span>您还没有添加优惠商品到购物车～</span>
                        </div>
                    </template>
                </template>
            </template>

            <div class="nodata" v-else>
                <img src="@/assets-v2/images/nodata.png" />
                <span>您还没有添加商品到购物车～</span>
            </div>

            <div class="card-bottom">
                <div class="promotion" style="display: flex; align-items: center; justify-content: center;">
                  当前订单<span class="red" style="margin: 0; margin-left: 5px;">￥{{ cardInfo.allTotalMoney }}</span>
                  ，特价立减<span class="red" style="margin: 0; margin-left: 5px;">￥{{ cardInfo.reducedPriceTotal }}</span>
                  <span v-if="!cardInfo.piaoZhe3TrueOrFalse">，现金返点<span class="red">￥{{ cardInfo.cashAmount }}</span></span>
                  <!--condTypeCode 1.实销  2.订单-->
                  <template v-if="cardInfo.cartOrderReturnProportionVO && !cardInfo.activityNextInfoVO && cardInfo.cartOrderReturnProportionVO.condTypeCode == 2">
                    <!--isMax1 = -2  订单实付金额/订单金额不满足-->
                    <span v-if="cardInfo.cartOrderReturnProportionVO.isMax1 == -2">
                      选返利商品，满 {{ cardInfo.cartOrderReturnProportionVO.orderActivityCondMeas }} 元，
                      最高返{{ cardInfo.cartOrderReturnProportionVO.maxOrderDiscountMeas }}%
                    </span>

                    <!--isMax1 = -1 （金额满足，新品比例未达到最低）-->
                    <!--isMax1 = 0 （新品满足比例）-->
                    <span v-if="cardInfo.cartOrderReturnProportionVO.isMax1 == -1 || cardInfo.cartOrderReturnProportionVO.isMax1 == 0">
                      当前整单返利：{{ cardInfo.cartOrderReturnProportionVO.orderDiscountMeas }}%，
                      当前返利金额：{{ cardInfo.cartOrderReturnProportionVO.orderReturnProportionAmount }}，
                      再凑{{ cardInfo.cartOrderReturnProportionVO.scrapeTogetherABill }}，
                      享整单{{ cardInfo.cartOrderReturnProportionVO.nextOrderDiscountMeas }}%返利，
                      返利金额{{ cardInfo.cartOrderReturnProportionVO.nextOrderReturnProportionAmount }}
                    </span>

                    <!--isMax1 = 1 （新品已达到最高比例）-->
                    <span v-if="cardInfo.cartOrderReturnProportionVO.isMax1 == 1">
                      当前整单返利：{{ cardInfo.cartOrderReturnProportionVO.orderDiscountMeas }}% ，
                      当前返利金额：{{ cardInfo.cartOrderReturnProportionVO.orderReturnProportionAmount }}，
                      已满足最高返利比例，返利金额{{ cardInfo.cartOrderReturnProportionVO.orderReturnProportionAmount }}
                    </span>
                  </template>

                  <div style="display: flex; align-items: center; justify-content: center;" v-if="cardInfo.activityNextInfoVO">
                    <p style="margin-left: 20px;">整单立减：</p>
                    <span class="red" style="margin-left: 0; margin: 0;">
                      ￥{{ cardInfo.activityNextInfoVO.type == 0 ? 0 : cardInfo.activityNextInfoVO.reductionMoney }}
                    </span>元
                    <p style="margin-left: 20px;">
                      满￥{{ cardInfo.activityNextInfoVO.thresholdMoney }}整单立减{{ cardInfo.activityNextInfoVO.orderReturnMoneyPercent }}%
                    </p>
                  </div>

                  <span class="f-right link-color" @click="jumpRebate()" v-if="!cardInfo.activityNextInfoVO">
                    去凑单<i class="iconfont icon-arrow-right"></i>
                  </span>
                </div>
                <div class="card-op v2-g-flex-row v2-g-flex-space-between">
                    <div class="card-op-left v2-g-flex-row ">
                        <span class="card-op-sel link-color" @click="selectAll">
                          <input type="checkbox" v-model="isAll" />全选
                        </span>
                        <span class="card-op-del-sel link-color" @click="delSel">
                          <img src="@/assets-v2/images/gwc_shanchu.png" style="width:16px;"/>删除所选
                        </span>
                        <span class="card-op-clean-invalid link-color" @click="cleanInvalidProduct">
                            <img src="@/assets-v2/images/gwc_qingkong.png" style="width:16px;"/>
                            清空无效商品
                        </span>
                    </div>
                    <div class="card-op-right">
                        <span>已选商品<i class="red">{{ checkedCount }}</i></span>
                        <span>赠品<i class="red">{{ statisticsOnTheNumberOfGifts }}</i></span>
                        <span>活动价<i class="red">￥{{ cardInfo.productSumPrice || 0 }}</i></span>
                        <span
                          v-if="cardInfo.cartOrderReturnProportionVO && cardInfo.cartOrderReturnProportionVO.isShow === 1">
                          299凑单返利
                          <i class="red">￥{{ cardInfo.isMax > -1 ? cardInfo.giftAmount : 0 }}</i>(次日到帐)
                        </span>
                        <span v-if="cardInfo.orderRebateActivityTips">
                          {{ cardInfo.orderRebateActivityTips }}
                          <i class="red">￥{{ cardInfo.activityPriceFor99_5 }}</i>(次日到帐)
                        </span>
                        <button type="button" class="link-color" @click="showMediumPackage"
                            :disabled="isAllUnCheck || isShowTips || isNum() || !hasChecked()">下单结算</button>
                    </div>
                </div>
            </div>
        </div>
        <PageBottom></PageBottom>
        <Dialog :show="isShowMediumPackage" title="中包装购买提醒" width="800px" height="400px"
            @close="isShowMediumPackage = false">

            <div class="groupCont">
                <div class="mpList" v-for="item in mediumPackageList" :key="item.productId">
                    <div class="topInfo">
                        <p class="productName">{{ item.productName }}</p>
                        <p>{{ item.specifications }}</p>
                        <p>
                            ¥ {{ Number(item.preferentialPrice) | NumFormat }}
                            <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                        </p>
                        <p class="factory">{{ item.produceFactory }}</p>
                    </div>
                    <div class="mpInfo">
                        <p>
                            购买 <span>{{ item.totalNumber }}</span> 个中包装
                        </p>
                        <p>
                            实际购买
                            <span>{{ Number(item.totalNumber) * item.mediumPackage }}</span>{{ item.sellUnit }}
                        </p>
                        <p>
                            小计¥
                            <span>{{ Number(item.subtotalMoney) | NumFormat }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <template #footer>
                <button type="button" @click="settleOrder"> 确定 </button>
                <button type="button" @click="isShowMediumPackage = false">取消</button>
            </template>
        </Dialog>

        <Dialog :show="isshowqy"  title="提示" width="600px" height="auto" @close="isshowqy=false">
          <!--托运信息有【邮政】，起送金额为500；非邮政，起送金额为299-->
          <div class="fd-rule2">暂时无法提交，下单金额需满足{{ minimumDeliveryAmount }}元<br/></div>
          <div class="fd-rule1">一、适用对象</div>
          <div class="fd-rule2">配送方式为托运的客户<br/></div>
          <div class="fd-rule1">二、启运金额</div>
          <div class="fd-rule2">订单金额≥{{ minimumDeliveryAmount }}元; <br/></div>
        </Dialog>

        <Dialog :show="userArrears" :hideClose=true title="欠款提示" width="533px" height="400px">
              <div class="proBox1">
                  <div class="proBox-title">
                      <img src="@/assets-v2/images/img_adorn.png" alt="" />
                      <span v-if="arrearsData.days == 0 && arrearsData.haveOtherDebit == 1 && arrearsData.isLock === 0">明天将因欠款无法下单</span>
                      <span v-if="arrearsData.isLock === 1">因欠款无法下单</span>
                  </div>
                  <div class="proBox-msg">
                      <p>截止当前，<span style="color: #E5432E">您已累计欠款{{arrearsData.currentSumDebit}}元，</span></p>
                      <p>为不影响您正常下单，请立即还款。</p>
                  </div>
                  <div class="proBox-btn" @click="nowArrears">
                      <span>立即还款</span>
                  </div>
              </div>
        </Dialog>
        <RebateList :show="showRebate" @close="showRebate=false;copyCard()"></RebateList>
    </div>
</template>
<style scoped lang="less">
.list {

    .bar {
        justify-content: space-between;
        align-items: center;
        height: 51px;

        .btn {
            width: 120px;
            height: 40px;
            box-sizing: content-box;
            line-height: 40px;
            border-radius: 20px;
            font-size: 1.1rem;
            margin-right: 10px;
            border: 1px solid #EDEDED;

            &.sel {

                background: linear-gradient(0deg, #F8831A 0%, #FFD0A2 100%);
                font-weight: 500;
                color: #FFFFFF;


            }

            span {
                // margin: 0 5px;
            }
        }

        .pro {
            line-height: 40px;

            span {
                margin: 0 10px;
            }

            i {
                color: #E5432E;
                font-style: normal;
                font-weight: 600;
                margin: 0 5px;
            }
        }

        .search {
            height: 36px;
            background: #FFFFFF;
            border: 1px solid #EDEDED;
            border-radius: 18px;
            width: 340px;
            padding: 0 5px;
            font-size: 1rem;

            input {
                border: none;
                height: 100%;
                width: 80%;
            }

            .btn-search {
                font-weight: 400;
                color: #3875F6;
            }
        }
    }

    .t {
        height: 48px;
        background: #F3F3F3;
        line-height: 48px;
        font-size: .875rem;

        .sel {
            width: 80px;

            input[type=checkbox] {
                width: 15px;
                height: 15px;
                vertical-align: middle;
                border: 1px solid #EDEDED;
                margin-right: 3px;
            }
        }

        .product {
            width: 350px;
            text-align: center;
        }

        .price {
            width: 230px;
            text-align: center;
        }

        .num {
            width: 150px;
            text-align: center;
        }

        .total {
            width: 120px;
            text-align: center;
        }

        .op {
            text-align: center;
            width: 200px;
            &.yh{
                width: 260px;
            }
        }
    }

    .product-wrapper-col {

        width: 100%;
        border: 1px solid #ededed;
        border-bottom: none;

        &:nth-last-child(1) {
            border: 1px solid #ededed;
        }
    }

    .card-bottom {
        position: sticky;
        bottom: 10px;
        z-index: 10;

        .promotion {
            height: 40px;
            background: #FFE3B3;
            box-shadow: 0 3px 7px 0 rgba(241, 241, 241, 0.85);
            line-height: 40px;

            .red {
                color: #E5432E;
                font-weight: 600;
            }

            span {
                margin: 0 10px;

                &.f-right {
                    float: right;
                    color: #fff;
                    font-weight: 600;
                    background: #E5432E;
                    /* border-bottom-right-radius: 50px; */
                    border-radius: 50px;
                    padding: 0 15px;
                    line-height: 30px;
                    margin-top: 5px;
                }
            }
        }

        .card-op {
            line-height: 52px;
            height: 52px;
            background: #FFFFFF;
            box-shadow: 0 3px 7px 0 rgba(241, 241, 241, 0.85);
            margin-bottom: 40px;
            .card-op-left{
                span{
                    display: flex;
                    align-items: center;
                }
            }
            .card-op-right,
            .card-op-left {
                align-items: center;
                span {
                    margin: 0 15px;

                    &.card-op-sel {
                        input[type=checkbox] {
                            width: 15px;
                            height: 15px;
                            vertical-align: middle;
                            border: 1px solid #EDEDED;
                            margin-right: 3px;
                        }
                    }



                    &.card-op-clean-invalid{
                        i{
                            font-size: 0.85rem;
                            margin-right: 3px;
                        }
                    }
                    .red {
                        color: #E5432E;
                        font-weight: 600;
                        font-style: normal;
                        font-size: 1rem;
                        padding: 0 5px;
                    }
                }

            }

            .card-op-right {
                button {
                    width: 140px;
                    height: 40px;
                    background: #3875F6;
                    border-radius: 4px;
                    font-weight: 400;
                    color: #FFFFFF;
                    border: none;
                    cursor: pointer;
                    font-size: 1rem;

                    &:active {
                        box-shadow: 0 0 5px #8cabee;
                    }

                    &:disabled {
                        background-color: gainsboro;
                    }
                }
            }

        }
    }

    .nodata {
        margin: 50px auto;
        position: relative;

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            color: #999999;
        }
    }

}

.groupCont {

    margin: 0 auto;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
    color: #4a4a4a;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    .mpList {
        padding: 20px;
        border-bottom: 1px solid #ccc;

        .topInfo,
        .mpInfo {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
        }

        .productName,
        .factory {
            width: 35%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
        }

        .productName {
            width: 25%;
        }

        .mpInfo {
            font-size: 16px;
            font-weight: bold;
            margin-top: 10px;

            span {
                color: #D90202;
            }
        }
    }
}

.fd-rule1 {
    font-size: 18px;
    font-weight: bold;
}

.fd-rule2 {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;
}

//   系统提示
.tips-box {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    width: 1190px;
    margin: 0 auto;
    height: 40px;
    background: rgba(255, 251, 230, 1);
    border: 1px solid rgba(255, 229, 143, 1);

    p {
        font-size: 14px;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
    }

    p:first-child i {
        color: rgba(255, 191, 0, 1);
    }

    p:last-child {
        u {
            cursor: pointer;
            font-size: 14px;

            font-weight: 400;
            color: rgba(56, 141, 237, 1);
        }

        img {
            margin-left: 12px;
            cursor: pointer;
        }
    }
}
.proBox-title{
      font-size: 24px;
      color: #222222;
      display: flex;
      align-items: center;
      font-weight: bold;
      img{
        width: 17px;
        height: 27px;
      }
    }
    .proBox-msg{
      font-size: 20px;
      font-weight: 500;
      color: #222222;
      padding: 0 57px;
      margin-top: 32px;
    }
    .proBox-btn{
      width: 170px;
      height: 45px;
      background: #2979FF;
      border-radius: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin: 109px auto 0;
      cursor: pointer;
    }
</style>
<script>
import ShoppingCartWaresItem from '@/components-v2/product/ShoppingCartWaresItem.vue'
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import { mapActions } from 'vuex';
import { AccountStorage } from "@services/account";
import Dialog from '@/components-v2/v2-dialog/index'
import RebateList from '@/components-v2/rebate/index'
import {rebate} from "../../mixin/rebate"
const accountStorage = new AccountStorage();
export default {
    mixins:[rebate],
    data() {
        return {
            list: [],
            isSelectPromotionTab: false,//是否选择优惠tab
            q: null, //购物车搜索
            isAll: true, //是否全部选中
            isAllUnCheck: true, //是否全部没选中
            isShowMediumPackage: false,//显示中包装
            mediumPackageList: [], //中包装商品列表
            isshowqy: false, //托运客户提示
            isShowTips: false,
            userArrears: false,//欠款提示
            arrearsData: {},//欠款信息
            showRebate: false,
            promiton_tb: false,
            minimumDeliveryAmount: ''
        };
    },
    components: {
        ShoppingCartWaresItem,
        PageTop,
        PageTop1,
        PageBottom,
        Dialog,
        RebateList
    },
    computed: {
        totalCount() {
            return this.list.length;
        },
        checkedCount() {
            let c = 0;
            this.list.forEach(t => {
                if (t.isChecked) c ++;
            })
            return c;
        },
        statisticsOnTheNumberOfGifts() {
          let arr = this.list.filter(item => item.isChecked)
          let arr1 = [];
          arr.forEach(item => {
            if (item.gift) {
              arr1.push(item.gift.productCode)
            }
          })
          // 把赠品的商品编码去除重复值
          let uniqueArr = [...new Set(arr1)];
          return uniqueArr.length;
        },
        promotionLst() {
            let promotionLst = [];
            this.list.forEach(t => {
                if (t.activityVOList && t.activityVOList.length > 0) {
                    promotionLst.push(t)
                }
            })
            return promotionLst;
        },
    },
    created() {
        this.findCardList().then(() => {
            this.copyCard();
        });
        //获取欠款
        this.getArrears();
    },
    methods: {
        ...mapActions([
            'findCardList',
            'updateCard'
        ]),

        //获取用户ID
        getUserId() {
            let userInfo = accountStorage.getCacheUserInfo();
            if (userInfo) {
                return userInfo.userId;
            }
        },
        fresh() {
            let $this = this;
            this.findCardList().then(() => {

                $this.copyCard();
            });
        },
        isNum() {
            let a = [];
            if (!this.cardInfo.ocCartLists) return;
            this.cardInfo.ocCartLists.forEach(item => {
                if (item.isCheckedName === true) {
                    a.push(item);
                }
            });

            return a.some(it => {
                return Number(it.totalNumber) === 0;
            });
        },
        // 是否禁用提交按钮
        hasChecked() {
            return this.cardInfo.ocCartLists.some(item => {
                // this.ischeckNum = item.isCheckedName
                return item.isCheckedName === true;
            });
        },
        copyCard() {
            this.isAll = true;
            this.list = [];
            let $this = this;
            this.isAllUnCheck = true;

            if (this.cardInfo.num == undefined) {
                this.findCardList().then(() => {
                    if (!$this.cardInfo.ocCartLists) return;
                    $this.cardInfo.ocCartLists.forEach(t => {
                        if (t.isInvalid && t.availableNum > 0) {
                            if (!t.isChecked) {
                                $this.isAll = false;
                            }
                            if (t.isChecked == 1) {
                                $this.isAllUnCheck = false;
                            }
                        }

                        let o = {
                            mainImageUrl: t.picPath,
                            preferenticePrice: t.preferentialPrice
                        };
                        if (this.q && this.q.length > 0) {
                            if (t.productName.indexOf(this.q) >= 0) {
                                o = Object.assign(o, t);
                                $this.list.push(o);
                            }
                        } else {
                            o = Object.assign(o, t);
                            $this.list.push(o);
                        }
                    })
                });
            } else {
                if (!$this.cardInfo.ocCartLists) return;
                $this.cardInfo.ocCartLists.forEach(t => {
                    if (t.isInvalid && t.availableNum > 0) {
                        if (!t.isChecked) {
                            $this.isAll = false;
                        }
                        if (t.isChecked == 1) {
                            $this.isAllUnCheck = false;
                        }
                    }
                    let o = {
                        mainImageUrl: t.picPath,
                        preferenticePrice: t.preferentialPrice
                    };
                    if (this.q && this.q.length > 0) {
                        if (t.productName.indexOf(this.q) >= 0) {
                            o = Object.assign(o, t);
                            $this.list.push(o);
                        }
                    } else {
                        o = Object.assign(o, t);
                        $this.list.push(o);
                    }
                })
            }
        },
        /**
         * 删除选择商品
         */
        delSel() {
            let $this = this;

            let lstProductId = [];
            this.list.forEach(t => {
                if (t.isChecked) {
                    lstProductId.push(t.productId);
                }
            })
            if (lstProductId.length == 0) {
                $this.$messageTip("请选择商品后在删除", 'error')
                return;
            }
            this.$confirm('确认是否将选中商品移出购物车？', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消'
            }).then(() => {
                const url = "/pjyy-deepexi-order-center/api/v1/cart/removeCartList";
                const param = {
                    productId: lstProductId,
                    userId: this.getUserId()
                };

                $this.$getHttpClient().post(url, param).then((result) => {

                    if (result.data.code == '200') {
                        $this.$messageTip("删除成功！", 'success')
                        //刷新购物车
                        $this.fresh();
                    }
                });


                // 成功
            }).catch(() => {
                // 失败
            })
        },
        //获取欠款
        async getArrears() {
          if (accountStorage.getCacheUserInfo()) {
            let account = accountStorage.getCacheUserInfo().account;
            const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/creditStanding/pc/${account}`;
            const result = await this.$getHttpClient().get(URL);
            if (result.data.code == 200) {
              this.arrearsData = result.data.data;
              if (this.arrearsData.show) {
                if (!this.arrearsData.cashType) {
                  if (this.arrearsData.days == 0 || this.arrearsData.isLock === 1) {
                    this.userArrears = true
                  }
                }
              }
            }
          }
        },
        //立即还款
        async nowArrears() {
            let account = accountStorage.getCacheUserInfo().account;
            const URL = `/pjyy-deepexi-pay-center/rpc/v2/openPay/getWebPayLink1?erpId=${account}&orderSource=5`;
            const result = await this.$getHttpClient().get(URL);
            if (result.data.code == 200) {
                window.open(result.data.data)
            }
        },
        /**
         * 清空无效商品
         */
        async cleanInvalidProduct() {

            const url = "/pjyy-deepexi-order-center/api/v1/cart/emptyProduct?userId=" + this.getUserId()
            let r = await this.$getHttpClient().get(url);
            if (r.data.code == '200') {
                this.$messageTip("清除成功！", 'success');
                this.fresh();
            }
        },
        /**
         * 全部选中
         */
        async selectAll() {
            let lstProductId = [];
            if (this.cardInfo && this.cardInfo.ocCartLists) {
              this.cardInfo.ocCartLists.forEach(t => {
                lstProductId.push(t.productId);
              })
            }
            const url = "/pjyy-deepexi-order-center/api/v1/cart/findCartList";
            const param = {
                code: 3,
                productId: lstProductId,
                isChecked: this.isAll ? 0 : 1
            };
            let r = await this.$getHttpClient().post(url, param);
            if (r.data.code == '200') {
                this.updateCard(r.data.data);
                this.copyCard();
            }
        },
        /**
         * 购物车搜索
         */
        search() {
            this.copyCard();
        },
        jumpRebate() {
            this.showRebate=true;
           // this.$router.push({ name: 'v2-product', params: { id: '2' } })
        },

        async showMediumPackage() {
            let arr = [];
            if (this.cardInfo && this.cardInfo.ocCartLists) {
              this.cardInfo.ocCartLists.forEach(item => {
                if (item.isCheckedName && item.isMediumPackage == 1) {
                  arr.push(item);
                }
              });
            }
            if (arr.length > 0) {
                this.mediumPackageList = arr;
                this.isShowMediumPackage = true;
                return;
            }
            this.settleOrder();
        },
        async settleOrder() {
            const url = '/pjyy-deepexi-order-center/api/v1/order/OcOrder/savePreOrder';
            let r = await this.$getHttpClient().post(url, { cashAmount: null });
            if (r.data.code == '200') {
                this.$router.push({ name: 'v2-orderconfirm', params: { id: r.data.data } })
            } else {
                if (r.data.code === "100000") {
                    this.isShowTips = true;
                    return;
                }
                if (r.data.msg === "9999" || r.data.msg === "10009") {
                    //托运客户提示
                    this.isshowqy = true;
                    // 托运信息有【邮政】，起送金额为500；非邮政，起送金额为299
                    this.minimumDeliveryAmount = r.data.msg === "10009" ? 500 : 299;
                    return;
                }
                this.$messageTip(r.data.msg, 'error')
            }
        }
    }

}
</script>
